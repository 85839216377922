import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
import pricingService from "../Services/pricing.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as maths from 'mathjs';
import { useStore } from "../../../Store/PricingStore";
const math: any = maths.create(maths.all);
// math.config({
//     number: "BigNumber",
//   });
interface PropsType {
  title: string;
  data: any;
  type: string;
  uomVal: any;
  onNewWeightAdded: () => void;
}

  const AskFareWeightBreak = forwardRef(({
    title,
  data,
  type,
  uomVal,
  onNewWeightAdded,
  }: PropsType, ref) => {

  let { id } = useParams<any>();
  const [weightDatas, setWeightData] = useState<any>([]);
  const [tempWeightDatas, setTempWeightData] = useState<any>([]);
  const [efault, setEfault] = useState<any>(null);
  const [spreadDefault, setdefaultSpread] = useState<any>(null);
  const [button, setButton] = useState(false);
  const [nsvVal, setNsv] = useState<any>(null);
  const [bidVal, setBitVal] = useState<any>(null);

  const [nsvAskZus, nsvBidZus, spreadDefaultBid, setNsvDefaultAsk, setNsvDefaultBid, setSpreadDefaultBid,isruleExpired] = useStore((state: any) =>
    [state.nsvDefaultAsk, state.nsvDefaultBid, state.spreadDefaultBid, state.setNsvDefaultAsk, state.setNsvDefaultBid, state.setSpreadDefaultBid,state.isruleExpired]);

  const nsv: any = type == "BID" ? localStorage.getItem("nsvDefaultBid") : localStorage.getItem("nsvDefaultAsk");
  const bid: any = localStorage.getItem("spreadDefaultBid");

  useEffect(() => {
    fetchPricingRulesData();
    // console.log("nsvAskZus,nsvBidZus,spreadDefaultBid ", nsvAskZus, nsvBidZus, spreadDefaultBid);
  }, []);



  useEffect((): any => {
    // console.log("Hii");

    const eventListenerStorage = () => {

      if (type === "ASK" && !!nsv && nsvVal === null) {
        // console.log("SUCCESS !!!");

        setNsv(nsv)

      }
      if (type === "BID" && !!bid && bidVal === null) {
        setNsv(nsv)
        setBitVal(bid)
      }


    };
    eventListenerStorage();
    window.addEventListener("storage", eventListenerStorage);
    return () => window.removeEventListener("storage", eventListenerStorage);
  }, [nsv, bid]);

  useEffect((): any => {
    setWeightData(data);
    setWeightDataToLocalStorage(data);
  }, [data]);

  const fetchPricingRulesData = async () => {
    let data: any = await pricingService.getPricingMasterById(id);

    if (!!data?.data?.data) {
      let responseData = data.data.data;
      if (type === "ASK" && responseData.askDefaultNsv > -1) {
        // console.log("SUCCESS !!!");

        setNsv(responseData.askDefaultNsv);
        localStorage.setItem("nsvDefaultAsk", responseData.askDefaultNsv);
        setNsvDefaultAsk(responseData.askDefaultNsv);
      }
      if (type === "BID" && responseData.bidDefaultNsv > -1 && responseData.bidSpreadDefault > -1) {
        setNsv(responseData.bidDefaultNsv)
        setBitVal(responseData.bidSpreadDefault)
        localStorage.setItem("nsvDefaultBid", responseData.bidDefaultNsv);
        localStorage.setItem("spreadDefaultBid", responseData.bidSpreadDefault);
        setNsvDefaultBid(responseData.bidDefaultNsv);
        setSpreadDefaultBid(responseData.bidSpreadDefault);

      }
    }
  };

  // useEffect((): any => {

  //   if (!isUomDefaultSet) {
  //     console.log("METTT val",type);
  //     if (uomVal.length > 0) {
  //       const e: any = {
  //         target: {
  //           value: "",
  //         },
  //       };
  //       e.target.value = uomVal[0]?.uomCode;
  //       if (e.target.value) {
  //         changeValueInput(e, 0, "uom");
  //         console.log("METTT val", e.target.value);
  //         setIsUomDefault(true)
  //       }
  //     }

  //   }

  // }, [weightDatas]);


  const setWeightDataToLocalStorage = (data: any) => {
    if (type === "BID") {
      localStorage.setItem("weightDataBid", JSON.stringify(data));
    } else {
      localStorage.setItem("weightDataAsk", JSON.stringify(data));
    }
  };

  const changeValueInput = (e: any, index: number, key: string) => {
    let value = e.target.value;
    console.log("EEEEEE ", value, key, index);


    if (key == "nsv") {
      if (value <= 6 && value >= 0) {
        console.log("allowed", value);
        var re = /^(([0-6]{0,1})(\.[0-9]{1,2})?)$/;
        var match = re.exec(value);
        let result;
        if (match != null) {
          result = match[1];
          let temp = weightDatas;
          temp[index][key] = value;
          setWeightData(JSON.parse(JSON.stringify(temp)));
          setWeightDataToLocalStorage(temp);
        }
      } else {
        console.log("Not allowed");
      }
    } else {
      let temp = weightDatas;
      temp[index][key] = value;
      setWeightData(JSON.parse(JSON.stringify(temp)));
      setWeightDataToLocalStorage(temp);
    }

  };

  useImperativeHandle(ref, () => ({
    saveAllAskFareWeight() {
      console.log("Save Ask fare weight");

      const index = weightDatas.findIndex((r:any) => r.isNew);

      if (index > -1) {
        saveNewWeightBreak(weightDatas[index], index);
      }

      checkForUpdatedWeightBreaks();
    }

  }));

  const addNewWeightBreak = () => {
    let found = !!weightDatas?.find((data: any) => data.isNew);

    if (found) {
      toast.error("Only one weight break can be added at a time");
      return;
    }

    let weightTemp = weightDatas ? weightDatas : [];
    weightTemp.push({
      weightOrAmount: "W",
      askOrBid: type,
      // uom: uomVal[0].uomCode,
      lowerBound: "",
      upperBound: "",
      nsv: "",
      bidSpread: 0,
      isNew: true,
      isEditing: false,
      ruleWeightSequence: weightDatas ? weightDatas.length + 1 : 1,
      pricingMasterId: id,
    });
    console.log("setWeightData", weightDatas);

    setWeightData(JSON.parse(JSON.stringify(weightTemp)));
    setWeightDataToLocalStorage(weightTemp);
  };

  const checkForUpdatedWeightBreaks = async () => {
    let allValidW = true;
    let allValidM = true;

    let wgtWeightArray = weightDatas.filter(function (obj: any) {
      return obj.weightOrAmount == "W"
    })

    let amntWeightArray = weightDatas.filter(function (obj: any) {
      return obj.weightOrAmount == "A"
    })

    // console.log("wgtWeightArray ", wgtWeightArray, wgtWeightArray[0]["upperBound"] != wgtWeightArray[1]["lowerBound"]);
    // console.log("amntWeightArray ", amntWeightArray);

    for (const [index, el] of wgtWeightArray.entries()) {
      console.log(index, el);
      if (validateWeightBreakEdit(wgtWeightArray, el, index)) {
        allValidW = true;
      } else {
        allValidW = false;
        break;
      }
    }

    console.log("allValidWallValidW ", allValidW);


    for (const [index, el] of amntWeightArray.entries()) {
      console.log(index, el);
      if (validateWeightBreakEdit(amntWeightArray, el, index)) {
        allValidM = true;
      } else {
        allValidM = false;
        break;
      }
    }

    console.log("allValidMallValidM ", allValidM);

    if (allValidW && allValidM) {
      let i = 0;
      for await (const el of weightDatas) {
        if (el.isEditing) {
          console.log(i, el);
          await saveNewWeightBreak(el, i);
        }
        i++;
        // saveNewWeightBreak()
      }
    }

  };

  const validateWeightBreakEdit = (newWeightDatas: any[], weightData: any, i: any) => {
    const prevWeightBreak = i - 1;
    const nextWeightBreak = i + 1;

    if (parseFloat(weightData.lowerBound) >= parseFloat(weightData.upperBound)) {
      toast.error("Lower bound should be less than upper bound");
      return false;
    }
    if (newWeightDatas[prevWeightBreak] && newWeightDatas[nextWeightBreak]) {

      if (parseFloat(newWeightDatas[prevWeightBreak].upperBound) != parseFloat(weightData.lowerBound)) {
        toast.error("Current lower bound and previous upper bound shouldn't have gaps");
        return false;
      }
      if (parseFloat(newWeightDatas[nextWeightBreak].lowerBound) != parseFloat(weightData.upperBound)) {
        toast.error("Current upper bound and next lower bound shouldn't have gaps");
        return false;
      }


    } else if (newWeightDatas[nextWeightBreak]) {
      if (parseFloat(newWeightDatas[nextWeightBreak].lowerBound) != parseFloat(weightData.upperBound)) {
        toast.error("Current upper bound and next lower bound shouldn't have gaps");
        return false;
      }

    } else if (newWeightDatas[prevWeightBreak]) {
      // console.log("camms ", newWeightDatas[prevWeightBreak].lowerBound, "kmini 2", weightData.upperBound);

      if (parseFloat(newWeightDatas[prevWeightBreak].upperBound) != parseFloat(weightData.lowerBound)) {
        toast.error("Current lower bound and previous upper bound shouldn't have gaps");
        return false;
      }

    }

    return true;

  }

  const validateWeightBreak = (weightData: any) => {
    if (weightDatas.length == 0) {
      return true;
    }

    let amount =
      parseFloat(weightData.upperBound) - parseFloat(weightData.lowerBound);
    if (amount <= 0) {
      toast.error("Lower bound should be less than upper bound");
      return false;
    }

    if (type == "BID") {
      if (
        !weightData.weightOrAmount ||
        !weightData.nsv ||
        !weightData.upperBound ||
        (!weightData.lowerBound && weightData.lowerBound < 0) ||
        // !weightData.uom ||
        !weightData.bidSpread
      ) {
        toast.error("Add values to all the fields");
        return false;
      }
    } else {
      if (
        !weightData.weightOrAmount ||
        !weightData.nsv ||
        !weightData.upperBound ||
        (!weightData.lowerBound && weightData.lowerBound < 0)
        // !weightData.uom
      ) {
        toast.error("Add values to all the fields");
        return false;
      }
    }

    if (weightDatas.length == 1) {
      return true;
    }

    let newOne = weightDatas[weightDatas.length - 1];
    let tempDatas = JSON.parse(JSON.stringify(weightDatas));
    tempDatas.splice(weightDatas.length - 1, 1);

    let amountData = tempDatas.filter((data: any) => {
      return data.weightOrAmount == "A";
    });

    let weightRelatedData = tempDatas.filter((data: any) => {
      return data.weightOrAmount == "W";
    });

    if (amountData.length == 0 && newOne.weightOrAmount == "A") {
      return true;
    }

    let weighTempData = [];

    if (weightRelatedData.length == 0 && newOne.weightOrAmount == "W") {
      return true;
    }

    if (newOne.weightOrAmount == "W") {
      weighTempData = weightRelatedData;
    } else {
      weighTempData = amountData;
    }

    let otherValues = weighTempData.reduce(
      (acc: any, current: any) => [
        ...acc,
        current.lowerBound,
        current.upperBound,
      ],
      []
    );

    let max = math.max(otherValues);
    let min = math.min(otherValues);

    if (parseFloat(newOne.upperBound) <= parseFloat(newOne.lowerBound)) {
      //TODO: add a validation error - "LowerBound must Lower"
      toast.error("Lower bound should be less than upper bound");
      return false;
    }

    console.log("parseFloat(newOne.upperBound)", parseFloat(newOne.upperBound));
    console.log("newOne.lowerBound", parseFloat(newOne.lowerBound));
    console.log("MIN", min, "MAX", max);

    if (
      parseFloat(newOne.upperBound) < min ||
      parseFloat(newOne.lowerBound) == max
    ) {
      return true;
    }

    if (parseFloat(newOne.lowerBound) > max) {
      toast.error("Current lower bound and previous upper bound shouldn't have gaps");
    } else {
      toast.error("Can't add lower bound and upper bound range as existing data");
    }

    return false;
  };

  const saveNewWeightBreak = async (data: any, index: number) => {

    try {
      if (validateWeightBreak(data)) {
        if (type == "ASK") {
          delete data.bidSpread;
        }
        setButton(true);
        delete data.isNew;

        // data.nsv = Math.round(data.nsv/100 * Math.pow(10,4)) / Math.pow(10,4); // to make it nsv percentage
        console.log("DAATAAA : ", data);
        let responseData: any;
        if (data.isEditing) {
          delete data.isEditing;
          responseData = await pricingService.updateWeightBreak(data);
          console.log(responseData, "responseData editing");
        } else {
          delete data.isEditing;
          responseData = await pricingService.createWeightBreak(data);
          console.log(responseData, "responseData creating");
        }

        if (!!responseData?.data?.data) {
          let temp = weightDatas;

          temp[index].isNew = false;
          setWeightData(JSON.parse(JSON.stringify(temp)));

          setWeightDataToLocalStorage(temp);

          setEfault(efault);

          toast.success(`${type == "ASK" ? "Ask ": "Bid "}Weight break saved successfully`);
          onNewWeightAdded();
          return;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setButton(false);
    }
  };

  const defaultChangedOld = (e: any) => {
    let tempQuantity = e.target.value;
    // setEfault(tempQuantity);
    setNsv(tempQuantity)
    if (type === "BID") {
      localStorage.setItem("nsvDefaultBid", tempQuantity);
    } else {
      localStorage.setItem("nsvDefaultAsk", tempQuantity);
    }
  };

  const defaultChanged = (e: any) => {
    let tempQuantity = e.target.value;
    if (tempQuantity <= 6 && tempQuantity >= 0) {
      console.log("allowed", tempQuantity);
      var re = /^(([0-6]{0,1})(\.[0-9]{1,2})?)$/;
      var match = re.exec(tempQuantity);
      let result;
      if (match != null) {
        result = match[1];
        // setEfault(tempQuantity);

        setNsv(tempQuantity);
        console.log("TEMPPPPQUANTITYTYTY ", tempQuantity, Math.round(tempQuantity / 100 * Math.pow(10, 4)) / Math.pow(10, 4));
        // tempQuantity = Math.round(tempQuantity/100 * Math.pow(10,4)) / Math.pow(10,4); // converting to percentage
        if (type === "BID") {
          localStorage.setItem("nsvDefaultBid", tempQuantity);
          console.log("BID", type);
        } else {
          console.log("ASK", type);

          localStorage.setItem("nsvDefaultAsk", tempQuantity);
        }
      }
    } else {
      console.log("Not allowed");
    }
  };


  const defaultChangedSpread = (e: any) => {
    let spread = e.target.value;
    // setdefaultSpread(spread);
    setBitVal(spread)
    if (type === "BID") {
      localStorage.setItem("spreadDefaultBid", spread);
    }
  };

  const deleteWeightBreak = async (weightData: any, index: any) => {
    try {
      if (!!weightData?.id) {
        // if (responseData.data.success) {
        let tempData = weightDatas;
        tempData.splice(index, 1);
        await pricingService.deleteWeightBreak(weightData.id);

        setWeightData(JSON.parse(JSON.stringify(tempData)));
        setWeightDataToLocalStorage(tempData);
        toast.success("Successfully deleted the weight data");
        // }
        onNewWeightAdded();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editWeightBreak = async (weightData: any, index: any) => {
    try {
      if (!!weightData?.id) {
        console.log("EDIT WEIGHT DATA in ", weightData, "index", index);
        let newArr: any = [...weightDatas];

        newArr[index].isEditing = true;
        setWeightData(newArr);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="mt-4 bg-white shadow-sm overflow-hidden border border-gray-300 rounded mb-5">
      {/* <ToastContainer containerId="message"/> */}
      <div className="flex items-center justify-between p-2 px-3 pl-5 bg-gray-50 border-b-2">
        <h2 className="font-bold">
          {title ? title : "Ask-Fare / Weight Break"}
        </h2>
        {weightDatas.some((obj: any) => { return obj.isEditing }) ?//isWeightBreakEditing ?
          <></>
          // <button
          //   id={type == "ASK" ? "ask-fare-add-btn" : "bid-fare-add-btn"}
          //   className="bg-purple-950 uppercase text-white px-4 py-2 rounded font-bold"
          //   onClick={checkForUpdatedWeightBreaks}
          //   type="button"
          // >
          //   Save
          // </button>
          :
          <button
            id={type == "ASK" ? "ask-fare-add-btn" : "bid-fare-add-btn"}
            className={`bg-purple-950 uppercase text-white px-4 py-2 rounded font-bold ${isruleExpired ? 'opacity-50' : ''}`}
            onClick={addNewWeightBreak}
            disabled={isruleExpired}
            type="button"
          >
            Add
          </button>
        }


      </div>
      <div className="p-5 mb-4 flex overflow-hidden">
        <table className="rounded-t-lg m-5 w-full mx-auto bg-gray-100 border border-gray-300 text-sm">
          <tr className="text-left border-b border-gray-300">
            <th className="px-4 py-2 border-r border-gray-300">Amount/Wgt</th>
            {/* <th className="px-4 py-2 border-r border-gray-300">UOM</th> */}
            <th className="px-4 py-2 border-r border-gray-300">Lower Bound</th>
            <th className="px-4 py-2 border-r border-gray-300">Upper Bound</th>
            <th className="px-4 py-2 border-r border-gray-300">
              <div className="flex items-center justify-between whitespace-nowrap">
                NSV
                <div className="bg-grey-lighter relative ml-2 flex items-center justify-between">
                  <span className="text-xs absolute text-gray-500 top-2 left-2">
                    Default
                  </span>
                  <input
                    className="pl-20 appearance-none block text-right w-40  text-grey-darker border border-grey-lighter rounded py-1 px-4"
                    id="grid-city"
                    name="bid"
                    type="number"
                    value={nsvVal}
                    disabled={isruleExpired}
                    placeholder=""
                    min={0}
                    max={6}
                    step={0.01}
                    // onKeyDown={(e) => {
                    //   e.preventDefault();
                    // }}
                    onChange={(e) => defaultChanged(e)}
                  />
                </div>
              </div>
            </th>
            {type == "BID" && (
              <th className="px-4 py-2 border-r border-gray-300">
                <div className="flex items-center justify-between whitespace-nowrap">
                  Bid Spread
                  <div className="bg-grey-lighter relative ml-2 flex items-center justify-between">
                    <span className="text-xs absolute text-gray-500 top-2 left-2">
                      Default
                    </span>
                    <input
                      className="pl-20 appearance-none block text-right w-40  text-grey-darker border border-grey-lighter rounded py-1 px-4"
                      id="grid-city"
                      name="bid"
                      type="number"
                      value={bidVal}
                      disabled={isruleExpired}
                      placeholder=""
                      onChange={(e) => defaultChangedSpread(e)}
                    />
                  </div>
                </div>
              </th>
            )}
            <th className="px-4 py-2 text-right">Action</th>
            {/* <th className="px-4 py-3">Amount/Wgt</th> */}
          </tr>

          {!!weightDatas?.length &&
            weightDatas.map((weightData: any, index: number) => {
              if (!weightData.isNew) {
                // console.log("weightDataweightDataweightData ", weightData);

                return (
                  <tr className="bg-white border-b border-gray-300">

                    {weightData.isEditing ?
                      <td className="px-4 py-3 border-r border-gray-300">
                        <select
                          onChange={(e) =>
                            changeValueInput(e, index, "weightOrAmount")
                          }
                          className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-2 px-4 pr-8 rounded"
                          id="grid-state"
                          value={weightData.weightOrAmount}
                        >
                          <option value="W">Wgt</option>
                          <option value="A">Amount</option>
                        </select>
                      </td>
                      :
                      <td className="px-4 py-3 border-r border-gray-300">
                        {weightData.weightOrAmount == "W" ? "Wgt" : "Amount"}
                      </td>
                    }

                    {/* <td className="px-4 py-2 border-r border-gray-300">
                      {weightData.uom}
                    </td> */}
                    {weightData.isEditing ?
                      <td className="px-4 py-3 border-r border-gray-300">
                        <input
                          className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                          id="lowerBound"
                          name="bid"
                          type="number"
                          placeholder="Lower Bound"
                          value={weightData.lowerBound}
                          onChange={(e) =>
                            changeValueInput(e, index, "lowerBound")
                          }
                        />
                      </td>
                      :
                      <td className="px-4 py-2 border-r border-gray-300">
                        {weightData.lowerBound}
                      </td>
                    }
                    {weightData.isEditing ?
                      <td className="px-4 py-3 border-r border-gray-300">
                        <input
                          className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                          id="upperBound"
                          name="bid"
                          type="number"
                          placeholder="Upper Bound"
                          value={weightData.upperBound}
                          onChange={(e) =>
                            changeValueInput(e, index, "upperBound")
                          }
                        />
                      </td>
                      :
                      <td className="px-4 py-2 border-r border-gray-300">
                        {weightData.upperBound}
                      </td>
                    }
                    {weightData.isEditing ?
                      <td className="px-4 py-3 border-r border-gray-300">
                        <input
                          className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                          id="nsv"
                          name="nsv"
                          type="number"
                          placeholder="NSV"
                          min={0}
                          max={6}
                          step={0.01}
                          // onKeyDown={(e) => {
                          //   e.preventDefault();
                          // }}
                          value={weightData.nsv}
                          onChange={(e) => changeValueInput(e, index, "nsv")}
                        />
                      </td>
                      :
                      <td className="px-4 py-2 border-r border-gray-300">
                        {weightData.nsv}
                      </td>
                    }
                    {type == "BID" && !weightData.isEditing &&
                      <td className="px-4 py-2 border-r border-gray-300">
                        {weightData.bidSpread}
                      </td>
                    }
                    {type == "BID" && weightData.isEditing &&
                      <td className="px-4 py-3 border-r border-gray-300">
                        <input
                          className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                          id="nsv"
                          name="nsc"
                          type="number"
                          placeholder="Spread for Bid"
                          value={weightData.bidSpread}
                          onChange={(e) =>
                            changeValueInput(e, index, "bidSpread")
                          }
                        />
                      </td>
                    }
                    <td className="px-4 py-2 text-center">
                      {weightData.isDefault && (
                        <input
                          type="checkbox"
                          id="vehicle3"
                          name="vehicle3"
                          value={weightData.isDefault}
                          checked={weightData.isDefault}
                        ></input>
                      )}
                      {weightData.isEditing ?
                        // <button
                        //   id={
                        //     type == "ASK"
                        //       ? "ask-fare-save-btn"
                        //       : "bid-fare-save-btn"
                        //   }
                        //   className="bg-purple-950 uppercase text-white px-4 py-2 rounded font-bold"
                        //   onClick={(e) => saveNewWeightBreak(weightData, index)}
                        //   type="button"
                        // // disabled={!!button}
                        // >
                        //   Save
                        // </button>

                        <></>
                        :
                        <>
                          <button
                            id={
                              type == "ASK"
                                ? "ask-weight-edit-btn"
                                : "bid-weight-edit-btn"
                            }
                            disabled={!!weightDatas?.find((data: any) => data.isNew) || isruleExpired}
                            className={`uppercase text-red-500 border px-4 py-2 rounded font-bold ${isruleExpired ? 'opacity-50' : ''}`}
                            onClick={(e) => editWeightBreak(weightData, index)}
                            type="button"
                          >
                            <svg className="w-5 text-red-500 edit-weightbreak" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2" d="M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z" /></svg>
                          </button>

                          <button
                            id={
                              type == "ASK"
                                ? "ask-weight-delete-btn"
                                : "bid-weight-delete-btn"
                            }
                            disabled={isruleExpired}
                            className={`uppercase text-red-500 border ml-3 px-4 py-2 rounded font-bold ${isruleExpired ? 'opacity-50' : ''}`}
                            onClick={(e) => deleteWeightBreak(weightData, index)}
                            type="button"
                          >
                            <svg
                              className="w-5 text-red-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>
                        </>
                      }
                    </td>
                  </tr>
                );
              } else if (weightData.isNew) {
                return (
                  <tr className="bg-white border-b bg-gray-100 border-gray-300">
                    <td className="px-4 py-3 border-r border-gray-300">
                      <select
                        onChange={(e) =>
                          changeValueInput(e, index, "weightOrAmount")
                        }
                        className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-2 px-4 pr-8 rounded"
                        id="grid-state"
                        value={weightData.weightOrAmount}
                      >
                        <option value="W">Wgt</option>
                        <option value="A">Amount</option>
                      </select>
                    </td>
                    {/* UOM commented since it's in the header level now */}
                    {/* <td className="px-4 py-3 border-r border-gray-300">
                      <select
                        name="bid"
                        onChange={(e) => changeValueInput(e, index, "uom")}
                        className="border border-gray-300 rounded-md appearance-none block w-full bg-grey-lighter text-grey-darker border-grey-lighter rounded py-2 px-4"
                        id="grid-state"
                        value={weightData.uom}
                        disabled={false}
                      >
                        {uomVal.length > 0 &&
                          uomVal.map((data: any,i:any) => {
                            return (
                              
                              <option value={data.uomCode}>
                                {data.uomCode}
                              </option>
                            );
                          })}
                      </select>
                    </td> */}

                    <td className="px-4 py-3 border-r border-gray-300">
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="lowerBound"
                        name="bid"
                        type="number"
                        placeholder="Lower Bound"
                        value={weightData.lowerBound}
                        onChange={(e) =>
                          changeValueInput(e, index, "lowerBound")
                        }
                      />
                    </td>
                    <td className="px-4 py-3 border-r border-gray-300">
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="upperBound"
                        name="bid"
                        type="number"
                        placeholder="Upper Bound"
                        value={weightData.upperBound}
                        onChange={(e) =>
                          changeValueInput(e, index, "upperBound")
                        }
                      />
                    </td>
                    <td className="px-4 py-3 border-r border-gray-300">
                      <input
                        className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                        id="nsv"
                        name="nsv"
                        type="number"
                        placeholder="NSV"
                        min={0}
                        max={6}
                        step={0.01}
                        // onKeyDown={(e) => {
                        //   e.preventDefault();
                        // }}
                        value={weightData.nsv}
                        onChange={(e) => changeValueInput(e, index, "nsv")}
                      />
                    </td>

                    {type == "BID" && (
                      <td className="px-4 py-3 border-r border-gray-300">
                        <input
                          className="rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4"
                          id="nsv"
                          name="nsc"
                          type="number"
                          placeholder="Spread for Bid"
                          value={weightData.bidSpread}
                          onChange={(e) =>
                            changeValueInput(e, index, "bidSpread")
                          }
                        />
                      </td>
                    )}

                    <td className="px-4 py-3 text-center">
                      {/* <button
                        id={
                          type == "ASK"
                            ? "ask-fare-save-btn"
                            : "bid-fare-save-btn"
                        }
                        className="bg-purple-950 uppercase text-white px-4 py-2 rounded font-bold"
                        onClick={(e) => saveNewWeightBreak(weightData, index)}
                        type="button"
                      // disabled={!!button}
                      >
                        Save
                      </button> */}
                    </td>
                  </tr>
                );
              }
            })}
        </table>
      </div>
    </div>
  );
});

// export default AskFareWeightBreak;
export default memo(AskFareWeightBreak);