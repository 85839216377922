import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuthStore } from "../../../Store/AuthStore";
import { useHistory } from "react-router-dom";
import { Transition } from "@headlessui/react";
import logo from "../../../Images/logo.svg";
import Collapsible from "react-collapsible";

const Nav = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);

  let history = useHistory();
  const { logout, permissions, userData } = useAuthStore();
  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logout();
    history.push("/login");
  };

  const hasAnyAccess = (data: any) => {
    if (
      permissions?.filter(
        (permission: any) => permission.permissionCode === "super.admin"
      ).length === 1
    ) {
      return true;
    } else {
      return (
        permissions?.filter((permission: any) =>
          data?.includes(permission.permissionCode)
        ).length > 0
      );
    }
  };

  return (
    <>
      <nav
        className={`bg-purple-950 w-64 z-20 fixed min-h-screen h-screen inset-y-0 p-0 overflow-y-auto ${
          !props.open ? `hidden` : ``
        }`}
      >
        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div className="flex-shrink-0 flex items-center p-5">
            <img
              className="block lg:hidden h-8 w-36"
              src={logo}
              alt="Workflow"
            />
            <img
              className="hidden lg:block h-8 w-36"
              src={logo}
              alt="Workflow"
            />
          </div>
        </div>

        <div className="flex justify-center text-center pb-5 text-white">
          <div className="justify-center">
            <div className="text-center">
              <div style={{ fontSize: "12px" }}>{userData.given_name}</div>
              <div
                className="text-center text-gray-400"
                style={{ fontSize: "12px" }}
              >
                {userData.email}
              </div>
              <div
                className="flex justify-center text-gray-400"
                style={{ fontSize: "12px" }}
              >
                <button onClick={handleLogout}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 0 24 24"
                    width="20px"
                    fill="#FFFFFF"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="menu">
          {hasAnyAccess([
            "trade.report.view",
            "trade.report.edit",
            "treasury.report.view",
            "treasury.report.edit",
            "management.report.view",
            "management.report.edit",
          ]) && (
            <Collapsible trigger="Dashboard" open={true}>
              {hasAnyAccess([
                "trade.report.view",
                "trade.report.edit",
                "management.report.view",
                "management.report.edit",
              ]) && (
                <NavLink
                  to="/report-dashboard/eb"
                  className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Trade Summary
                </NavLink>
              )}

              {hasAnyAccess([
                "treasury.report.view",
                "treasury.report.edit",
              ]) && (
                <NavLink
                  to="/report-dashboard/hp"
                  className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Treasury Report
                </NavLink>
              )}
              {hasAnyAccess(["dashboard.statistics.view"]) && (
                <NavLink
                  to="/statistics"
                  className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Statistics
                </NavLink>
              )}
            </Collapsible>
          )}

          {hasAnyAccess([
            "user.view",
            "assign.warehouse",
            "assign.role",
            "user.create",
            "customer.list.edit",
            "customer.list.view",
            "permission.edit",
            "permission.remove",
            "permission.add",
            "role.add",
            "role.edit",
            "role.remove",
            "partner.list.create",
            "partner.list.edit",
            "customer.group.create",
            "customer.group.view",
          ]) && (
            <Collapsible
              trigger="User Management"
              open={
                props.type == "UserModule" ||
                props.type == "AuthorizationModule"
              }
            >
              {hasAnyAccess([
                "permission.edit",
                "permission.remove",
                "permission.add",
                "role.add",
                "role.edit",
                "role.remove",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="Authorization"
                  open={props.type == "AuthorizationModule"}
                >
                  {hasAnyAccess([
                    "permission.edit",
                    "permission.remove",
                    "permission.add",
                  ]) && (
                    <NavLink
                      to="/permissions"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Permissions
                    </NavLink>
                  )}
                  {hasAnyAccess(["role.add", "role.edit", "role.remove"]) && (
                    <NavLink
                      to="/roles"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Roles
                    </NavLink>
                  )}
                </Collapsible>
              )}
              {hasAnyAccess(["partner.list.create", "partner.list.edit"]) && (
                // <Collapsible
                //     trigger="Partners" open={props.type == "PartnerModule"}>
                <NavLink
                  to="/partners"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Partners
                </NavLink>
                // </Collapsible>
              )}
              {hasAnyAccess([
                "user.view",
                "assign.warehouse",
                "assign.role",
                "user.create",
              ]) && (
                <NavLink
                  to="/users"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Users
                </NavLink>
              )}
              {hasAnyAccess(["customer.list.edit", "customer.list.view"]) && (
                <NavLink
                  to="/customers"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Customers
                </NavLink>
              )}
              {hasAnyAccess([
                "customer.group.create",
                "customer.group.view",
              ]) && (
                <NavLink
                  to="/group-list"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Customer Group
                </NavLink>
              )}
            </Collapsible>
          )}

          {hasAnyAccess(["investor.list"]) && (
            <Collapsible
              trigger="Business Management"
              open={props.type == "InvestorModule"}
            >
              {hasAnyAccess(["customer.list.edit", "customer.list.view"]) && (
                <NavLink
                  to="/investor-customer-list"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Customers
                </NavLink>
              )}
              {hasAnyAccess([
                "customer.group.create",
                "customer.group.view",
              ]) && (
                <NavLink
                  to="/investor-order-list"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Orders
                </NavLink>
              )}
              {hasAnyAccess([
                "user.view",
                "assign.warehouse",
                "assign.role",
                "user.create",
              ]) && (
                <NavLink
                  to="/investors"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Business Partners
                </NavLink>
              )}
            </Collapsible>
          )}

          {hasAnyAccess([
            "document.type.edit",
            "document.type.create",
            "bank.details.edit",
            "bank.details.delete",
            "bank.details.create",
            "reference.master.create",
            "reference.master.edit",
            "reference.master.view",
            "metal.master.edit",
            "metal.master.create",
            "currency.master.create",
            "currency.master.edit",
            "tax.category.master.create",
            "tax.category.master.edit",
            "tax.master.create",
            "tax.master.edit",
            "tax.details.master.create",
            "tax.details.master.edit",
            "item.master.view",
            "uom.master.create",
            "uom.master.edit",
            "uom.conversion.master.create",
            "uom.conversion.master.edit",
            "country.master.create",
            "country.master.edit",
            "state.master.create",
            "state.master.edit",
            "city.master.create",
            "city.master.edit",
            "tax.category.look.up.master.create",
            "tax.category.look.up.master.edit",
            "hsn.master.view",
            "customs.duty.master.create",
            "customs.duty.master.edit",
          ]) && (
            <Collapsible
              trigger="Master Tables"
              open={
                props.type == "MasterModule" ||
                props.type == "UOMModule" ||
                props.type == "TaxModule" ||
                props.type == "LocationModule"
              }
            >
              <NavLink
                to="/master/ebmastersync"
                className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
              >
                Data Sync
              </NavLink>
              {hasAnyAccess(["document.type.edit", "document.type.create"]) && (
                // <Collapsible trigger="Onboarding" open={props.type == "OnboardingModule"}>
                <NavLink
                  to="/document-config"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Document Types
                </NavLink>
                // </Collapsible>
              )}
              {hasAnyAccess([
                "bank.details.edit",
                "bank.details.delete",
                "bank.details.create",
              ]) && (
                // <Collapsible trigger="Bank Details" open={props.type == "BankDetailsModule"}>
                <NavLink
                  to="/bank-details"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Banks
                </NavLink>
                // </Collapsible>
              )}
              {hasAnyAccess([
                "currency.master.create",
                "currency.master.edit",
              ]) && (
                <NavLink
                  to="/currency-master"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Currencies
                </NavLink>
              )}
              {hasAnyAccess([
                "customs.duty.master.create",
                "customs.duty.master.edit",
              ]) && (
                <NavLink
                  to="/customs-duty"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Customs Duties
                </NavLink>
              )}
              {hasAnyAccess(["item.master.view"]) && (
                <NavLink
                  to="/item-master"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Items
                </NavLink>
              )}
              {hasAnyAccess(["metal.master.create", "metal.master.edit"]) && (
                <NavLink
                  to="/metal-master"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Metals
                </NavLink>
              )}
              {hasAnyAccess([
                "reference.master.create",
                "reference.master.edit",
                "reference.master.view",
              ]) && (
                <NavLink
                  to="/master/ebmaster"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  References
                </NavLink>
              )}
              {hasAnyAccess([
                "uom.master.create",
                "uom.master.edit",
                "uom.conversion.master.create",
                "uom.conversion.master.edit",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="UOM"
                  open={props.type == "UOMModule"}
                >
                  {hasAnyAccess(["uom.master.create", "uom.master.edit"]) && (
                    <NavLink
                      to="/uom"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      UOMs
                    </NavLink>
                  )}
                  {hasAnyAccess([
                    "uom.conversion.master.create",
                    "uom.conversion.master.edit",
                  ]) && (
                    <NavLink
                      to="/uom-conversion"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      UOM Conversion
                    </NavLink>
                  )}
                </Collapsible>
              )}
              {hasAnyAccess([
                "tax.category.look.up.master.create",
                "tax.category.look.up.master.edit",
                "tax.details.master.create",
                "tax.details.master.edit",
                "tax.master.create",
                "tax.master.edit",
                "tax.category.master.create",
                "tax.category.master.edit",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="Tax"
                  open={props.type == "TaxModule"}
                >
                  {hasAnyAccess([
                    "tax.category.master.create",
                    "tax.category.master.edit",
                  ]) && (
                    <NavLink
                      to="/master/tax-category"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Tax Categories
                    </NavLink>
                  )}
                  {hasAnyAccess(["tax.master.create", "tax.master.edit"]) && (
                    <NavLink
                      to="/master/tax"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Taxes
                    </NavLink>
                  )}
                  {hasAnyAccess([
                    "tax.details.master.create",
                    "tax.details.master.edit",
                  ]) && (
                    <NavLink
                      to="/master/tax-details"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Tax Details
                    </NavLink>
                  )}
                  {hasAnyAccess([
                    "tax.category.look.up.master.create",
                    "tax.category.look.up.master.edit",
                  ]) && (
                    <NavLink
                      to="/tax-category-lookup"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Tax Category Look Up
                    </NavLink>
                  )}
                </Collapsible>
              )}
              {hasAnyAccess([
                "state.master.create",
                "state.master.edit",
                "city.master.create",
                "city.master.edit",
                "country.master.create",
                "country.master.edit",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="Locations"
                  open={props.type == "LocationModule"}
                >
                  {hasAnyAccess([
                    "country.master.create",
                    "country.master.edit",
                  ]) && (
                    <NavLink
                      to="/master/country"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Country
                    </NavLink>
                  )}
                  {hasAnyAccess([
                    "state.master.create",
                    "state.master.edit",
                  ]) && (
                    <NavLink
                      to="/master/state"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      State
                    </NavLink>
                  )}
                  {hasAnyAccess(["city.master.create", "city.master.edit"]) && (
                    <NavLink
                      to="/master/city"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      City
                    </NavLink>
                  )}
                </Collapsible>
              )}
              {hasAnyAccess(["hsn.master.view"]) && (
                <NavLink
                  to="/hsn-master"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  HSN Master
                </NavLink>
              )}
              {hasAnyAccess(["vso.receipt"]) && (
                <NavLink
                  to="/warehouse-master"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Warehouse Master
                </NavLink>
              )}
            </Collapsible>
          )}

          {hasAnyAccess([
            "pricing.rule.master.create",
            "pricing.rule.master.view",
            "benchmark.view",
          ]) && (
            <Collapsible
              trigger="Pricing"
              //  open={props.type == "PricingModule"}
              open={false}
            >
              <NavLink
                to="/pricingfeed"
                className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
              >
                Pricing Feed
              </NavLink>
              {hasAnyAccess(["benchmark.view"]) && (
                <NavLink
                  to="/benchmark"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Benchmark
                </NavLink>
              )}
              {hasAnyAccess([
                "pricing.rule.master.create",
                "pricing.rule.master.view",
              ]) && (
                <NavLink
                  to="/pricing"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Pricing Rules
                </NavLink>
              )}
            </Collapsible>
          )}

          {/* <Collapsible trigger="Trade" open={props.type == "TradeExe"}>
            <NavLink
              to="/master"
              className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
            >
              Trading Rule Master
            </NavLink>
            <NavLink
              to="/trade"
              className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
            >
              Trading Rule
            </NavLink>
          </Collapsible> */}
          {hasAnyAccess([
            "management.report.view",
            "management.report.edit",
            "daily.bar.transactions",
            "daily.barlist.report.download",
            "vso.calendar.receipting.process",
            "vso.calendar.create",
            "vso.calendar.query",
            "daily.barlist",
            "vso.receipt",
          ]) && (
            <Collapsible
              trigger="Vaulting"
              open={false}
              // open={
              //   props.type == "VaultingModule" ||
              //   props.type == "VSOModule" ||
              //   props.type == "MetalInterchangeModule" ||
              //   props.type === "Reports"
              // }
            >
              <Collapsible
                openedClassName="level_two"
                className="level_two"
                trigger="VSO"
                open={props.type == "VSOModule"}
              >
                {hasAnyAccess([
                  "management.report.view",
                  "management.report.edit",
                ]) && (
                  <NavLink
                    to="/barlist/dashboard"
                    className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                  >
                    Dashboard
                  </NavLink>
                )}
                {hasAnyAccess([
                  "vso.calendar.create",
                  "vso.calendar.query",
                  "vso.calendar.edit",
                  "vso.receipt",
                ]) && (
                  <NavLink
                    to="/vso-calendar"
                    className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                  >
                    Calendar
                  </NavLink>
                )}
                {hasAnyAccess([
                  "vso.calendar.receipting.process",
                  "vso.receipt",
                ]) && (
                  <NavLink
                    to="/metal-receipting"
                    className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                  >
                    Receipting
                  </NavLink>
                )}
              </Collapsible>
              {hasAnyAccess([
                "bar.to.bar.exchange",
                "bar.to.bar.approve",
                "quantity.to.bar.exchange",
                "quantity.to.bar.approve",
                "vso.receipt",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="Metal Interchange"
                  open={props.type === "MetalInterchangeModule"}
                >
                  {hasAnyAccess([
                    "bar.to.bar.exchange",
                    "bar.to.bar.approve",
                    "vso.receipt",
                  ]) && (
                    <NavLink
                      to="/bar-to-bar"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Bar To Bar
                    </NavLink>
                  )}
                  {hasAnyAccess([
                    "quantity.to.bar.exchange",
                    "quantity.to.bar.approve",
                    "vso.receipt",
                  ]) && (
                    <NavLink
                      to="/quantity-to-bar"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Quantity To Bar
                    </NavLink>
                  )}
                  {hasAnyAccess([
                    "bar.to.bar.exchange",
                    "quantity.to.bar.exchange",
                    "bar.to.bar.approve",
                    "quantity.to.bar.approve",
                    "vso.receipt",
                  ]) && (
                    <NavLink
                      to="/metal-interchange-approve"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Approve
                    </NavLink>
                  )}
                </Collapsible>
              )}
              {hasAnyAccess([
                "daily.bar.transactions",
                "vso.receipt",
                "daily.barlist.report.download",
                "vso.receipt",
                "daily.barlist",
                "vso.receipt",
                "bar.summary.view",
                "report.bar.reconciliation",
                "vso.receipt",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="Reports"
                  open={false}
                  // open={props.type === "Reports"}
                >
                  <>
                    {hasAnyAccess([
                      "daily.bar.transactions",
                      "vso.receipt",
                    ]) && (
                      <NavLink
                        to="/barlist/summary"
                        className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                      >
                        Daily Bar List Transactions
                      </NavLink>
                    )}
                    {hasAnyAccess(["bar.summary.view"]) && (
                      <NavLink
                        to="/bar-summary"
                        className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                      >
                        Bar List Summary
                      </NavLink>
                    )}
                    {hasAnyAccess(["daily.barlist", "vso.receipt"]) && (
                      <NavLink
                        to="/barlist/daily"
                        className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                      >
                        Daily Barlist
                      </NavLink>
                    )}
                    {hasAnyAccess([
                      "daily.barlist.report.download",
                      "vso.receipt",
                    ]) && (
                      <NavLink
                        to="/daily-barlist-report"
                        className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                      >
                        Daily Barlist Reports
                      </NavLink>
                    )}

                    {hasAnyAccess([
                      "report.bar.reconciliation",
                      "vso.receipt",
                    ]) && (
                      <NavLink
                        to="/bar-reconciliation-report"
                        className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                      >
                        Bar Reconciliation Report
                      </NavLink>
                    )}
                    {hasAnyAccess([
                      "report.bar.reconciliation",
                      "vso.receipt",
                    ]) && (
                      <NavLink
                        to="/bar-segregation-list"
                        className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                      >
                        Bar Segregation List
                      </NavLink>
                    )}
                    {hasAnyAccess([
                      "report.bar.reconciliation",
                      "vso.receipt",
                    ]) && (
                      <NavLink
                        to="/customer-total-stock-list"
                        className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                      >
                        Customer Total Stock List
                      </NavLink>
                    )}
                  </>
                </Collapsible>
              )}
            </Collapsible>
          )}
          {hasAnyAccess([
            "manual.refund.view",
            "manual.refund.approve",
            "manual.refund.cancel",
            "trading.rule.master.create",
            "trading.rule.master.edit",
            "trading.rule.master.view",
            "discount.master.create",
            "discount.master.edit",
            "discount.master.view",
            "order.master.view.invoice",
            "order.master.view",
            "invoice.view",
            "invoice.download",
            "trading.rule.approve",
            "vso.receipt",
          ]) && (
            <Collapsible
              trigger="Trade Execution"
              open={props.type === "TradeExecution" || props.type === "Refund"}
            >
              {hasAnyAccess([
                "discount.master.create",
                "discount.master.edit",
                "discount.master.view",
              ]) && (
                <NavLink
                  to="/discount"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Discount
                </NavLink>
              )}
              {hasAnyAccess([
                "trading.rule.master.create",
                "price.rule.approve",
                "trading.rule.master.edit",
                "trading.rule.master.view",
              ]) && (
                <NavLink
                  to="/trade-execution"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Trade Execution Rules
                </NavLink>
              )}

              {hasAnyAccess(["gift.master.view"]) && (
                <NavLink
                  to="/gift-list"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Gift logs
                </NavLink>
              )}
              {hasAnyAccess([
                "order.master.view",
                "order.master.view.invoice",
                "vso.receipt",
              ]) && (
                <NavLink
                  to="/order"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Order
                </NavLink>
              )}
              {hasAnyAccess([
                "invoice.view",
                "invoice.download",
                "vso.receipt",
              ]) && (
                <NavLink
                  to="/invoice-list"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Invoice
                </NavLink>
              )}
              {hasAnyAccess([
                "manual.refund.view",
                "manual.refund.approve",
                "manual.refund.cancel",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="Refund"
                  open={props.type === "Refund"}
                >
                  {hasAnyAccess(["manual.refund.view"]) && (
                    <NavLink
                      to="/manual-refund"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Make Refund
                    </NavLink>
                  )}
                  {hasAnyAccess([
                    "manual.refund.approve",
                    "manual.refund.cancel",
                  ]) && (
                    <NavLink
                      to="/manual-refund-approve"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Approve
                    </NavLink>
                  )}
                </Collapsible>
              )}
            </Collapsible>
          )}
          {/* SIP PROMO CODE */}
          {hasAnyAccess([
            "manual.refund.view",
            "manual.refund.approve",
            "manual.refund.cancel",
            "trading.rule.master.create",
            "trading.rule.master.edit",
            "trading.rule.master.view",
            "discount.master.create",
            "discount.master.edit",
            "discount.master.view",
            "order.master.view.invoice",
            "order.master.view",
            "invoice.view",
            "invoice.download",
            "trading.rule.approve",
            "vso.receipt",
          ]) && (
            <Collapsible
              trigger="SIP"
              open={props.type === "SIP"}
            >
              {hasAnyAccess(["sip.promo.code.master.view"]) && (
                <NavLink
                  to="/sip"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                 SIP Promo Code
                </NavLink>
              )}
                </Collapsible>
              )}


          {hasAnyAccess([
            "report.settlement",
            "report.settlement.logs",
            "report.partner.settlement",
            "fund.management.report.download",
            "fund.management.report.view",
          ]) && (
            <Collapsible
              trigger="Payments"
              open={props.type == "PaymentModule"}
            >
              {/*<NavLink to="/fund" className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium">*/}
              {/*  Fund Management Log*/}
              {/*</NavLink>*/}
              <NavLink
                to="/fund-management"
                className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
              >
                Transaction
              </NavLink>
              {hasAnyAccess([
                "report.settlement",
                "report.settlement.logs",
              ]) && (
                <Collapsible
                  openedClassName="level_two"
                  className="level_two"
                  trigger="Settlement"
                  open={props.type == "PaymentModule"}
                >
                  {hasAnyAccess(["report.settlement"]) && (
                    <NavLink
                      to="/settlement-report"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Summary
                    </NavLink>
                  )}
                  {hasAnyAccess(["report.settlement.logs"]) && (
                    <NavLink
                      to="/settlement-report-api-logs"
                      className="font-small text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                    >
                      Logs
                    </NavLink>
                  )}
                </Collapsible>
              )}
              {hasAnyAccess(["report.partner.settlement"]) && (
                <NavLink
                  to="/partner-settlement-report"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Partner Settlement
                </NavLink>
              )}
              {hasAnyAccess(["sip.master.view"]) && (
                <NavLink
                  to="/sip-list"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  SIP Transactions
                </NavLink>
              )}
            </Collapsible>
          )}

          {hasAnyAccess(["other"]) && (
            <Collapsible trigger="Others" open={props.type == "Other"}>
              <NavLink
                to="/dashboard"
                className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
              >
                Dashboard
              </NavLink>

              <NavLink
                to="/test"
                className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
              >
                API test
              </NavLink>

              <NavLink
                to="/nfr"
                className="text-gray-300 hover:text-purple-300 block px-5 py-2 rounded-md text-base font-medium"
              >
                NFR Tables
              </NavLink>
            </Collapsible>
          )}

          {hasAnyAccess(["investor.list"]) && (
            <Collapsible
              trigger="Corporate Gifting"
              open={props.type == "InvestorModule"}
            >
              {hasAnyAccess(["customer.list.edit", "customer.list.view"]) && (
                <NavLink
                  to="/get-corporate-gifts"
                  className="text-sm text-gray-300 hover:text-purple-300 block nav-padding  font-medium"
                >
                  Gifts
                </NavLink>
              )}
            </Collapsible>
          )}
          {/* new code started */}
          {hasAnyAccess(["user.upload.view"]) && (
            <NavLink
              to="/bulk-upload"
              className=" Without-Collapsible__trigger hover:text-purple-300   font-medium"
            >
              User Upload
            </NavLink>
          )}
          {hasAnyAccess(["pushnotification.view"]) && (
            <NavLink
              to="/push-noti"
              className=" Without-Collapsible__trigger hover:text-purple-300   font-medium"
            >
              Push Notification
            </NavLink>
          )}

          {/* new code end */}

          {/*<a*/}
          {/*    onClick={handleLogout}*/}
          {/*    className="Signout"*/}
          {/*    role="menuitem"*/}
          {/*>*/}
          {/*  Sign Out*/}
          {/*</a>*/}
        </div>
      </nav>
      <nav className="bg-purple-950 absolute min-h-screen h-screen inset-y-0 hidden">
        <div className="max-w-xs w-full mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              {/* Mobile menu button */}
              <button
                className="inline-flex items-center justify-center p-2  text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block lg:hidden h-8 w-36"
                  src={logo}
                  alt="Workflow"
                />
                <img
                  className="hidden lg:block h-8 w-36"
                  src={logo}
                  alt="Workflow"
                />
              </div>
              {/* <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4"> */}
              {/* Current: "bg-gray-900 text-white", Default: "text-sm text-gray-300 hover:text-white"  */}
              {/* <NavLink to2"/" classNay-900 text-white block px-3 py-2  text-base font-medium">Dashboard</NavLink> */}
              {/* <NavLink
                  to="/users"
                  className="text-sm text-gray-300 hover:bg-gray-700 hover:text-white2block px-3 ed-md text-base font-medium"
                >
                  User List
                </NavLink>
              </div>
            </div> */}
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"  */}
                  {/* <NavLink to="/" className="bg-gray-900 text-white block px-3 py-2  text-base font-medium">Dashboard</NavLink> */}
                  <NavLink
                    to="/pricing"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                  >
                    Pricing
                  </NavLink>
                </div>
              </div>

              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"  */}
                  {/* <NavLink to="/" className="bg-gray-900 text-white block px-3 py-2  text-base font-medium">Dashboard</NavLink> */}
                  <NavLink
                    to="/users"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                  >
                    User List
                  </NavLink>
                </div>
              </div>

              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"  */}
                  {/* <NavLink to="/" className="bg-gray-900 text-white block px-3 py-2  text-base font-medium">Dashboard</NavLink> */}
                  <NavLink
                    to="/metal-master"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                  >
                    Vaulting
                  </NavLink>
                </div>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"  */}
                  {/* <NavLink to="/" className="bg-gray-900 text-white block px-3 py-2  text-base font-medium">Dashboard</NavLink> */}
                  <NavLink
                    to="/barlist/summary"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                  >
                    Daily Bar List Transactions
                  </NavLink>
                </div>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"  */}
                  {/* <NavLink to="/" className="bg-gray-900 text-white block px-3 py-2  text-base font-medium">Dashboard</NavLink> */}
                  <NavLink
                    to="/master/tables"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                  >
                    Master Tables
                  </NavLink>
                </div>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  <NavLink
                    to="/document-config"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                  >
                    Document Config
                  </NavLink>
                </div>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4">
                  <NavLink
                    to="/daily-barlist-report"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                  >
                    Daily Barlist Report
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <NavLink
                  to="/pricingfeed"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                >
                  Pricing Feed
                </NavLink>
              </div>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <NavLink
                  to="/Metal-Calendar"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
                >
                  VSO Calendar
                </NavLink>
              </div>
            </div>

            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">View notifications</span>
                  {/* Heroicon name: bell  */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>

                <div className="ml-3 relative">
                  <div>
                    <button
                      className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      id="user-menu"
                      aria-haspopup="true"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </button>
                  </div>
                  <Transition show={isOpen}>
                    <div
                      className="origin-top-right absolute right-0 mt-2 w-48  shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Your Profile
                      </a>

                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Settings
                      </a>

                      <button
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Sign out
                      </button>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>

            <div className="-mr-2 flex md:hidden">
              <button className="bg-gray-800 inline-flex items-center justify-center p-2  text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="hidden md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white"  */}
            <NavLink
              to="/"
              className="bg-gray-900 text-white block px-3 py-2  text-base font-medium"
            >
              Dashboard
            </NavLink>
            <NavLink
              to="/metal"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2  text-base font-medium"
            >
              Metals
            </NavLink>
          </div>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </div>
              <div className="ml-3">
                <div className="text-base font-medium leading-none text-white">
                  Tom Cook
                </div>
                <div className="text-sm font-medium leading-none text-gray-400">
                  tom@example.com
                </div>
              </div>
              <button className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">View notifications</span>
                {/* Heroicon name: bell  */}
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
              </button>
            </div>
            <Transition show={isOpen}>
              <div className="mt-3 px-2 space-y-1">
                <a
                  href="#"
                  className="block px-3 py-2  text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                >
                  Your Profile
                </a>

                <a
                  href="#"
                  className="block px-3 py-2  text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                >
                  Settings
                </a>

                <a
                  onClick={handleLogout}
                  href="#"
                  className="block px-3 py-2  text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                >
                  Sign out
                </a>
              </div>
            </Transition>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
