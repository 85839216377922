import UserListRoutes from "../Components/Userlist/Userlist.route";
import CustomerRoutes from "../Components/Customer/Customer.route";
import PartnerRoutes from "../Components/Partner/Partner.route";
import TestRoutes from "../Components/TestPage/Utils/Test.route";
import React from "react";
import PricingFeed from "../Components/TestPage/Compnents/PricingFeed";
import { TradeExeRoutes } from "../Components/TradeExe/Routes/TradeExe.route";
import { TradeExecutionRoutes } from "../Components/TradeExecution/Routes/TradeExecution.route";
import { OrderRoutes } from "../Components/Order/Routes/Order.route";
import { TaxCategoryRoutes } from "../Components/TaxCategory/Routes/TaxCategory.route";
import { DiscountRoutes } from "../Components/Discounts/Routes/Discount.route";
import { CountryRoutes } from "../Components/Country/Routes/Country.route";
import { TaxRoutes } from "../Components/Tax/Routes/Tax.route";
import { StateRoutes } from "../Components/State/Routes/State.route";
import { CityRoutes } from "../Components/City/Routes/City.route";
import { TaxDetailsRoutes } from "../Components/TaxDetails/Routes/TaxDetails.route";
import { CustomsDutyRoute } from "../Components/CustomsDuty/Routes/CustomsDuty.route";
import { FundMgtRoutes } from "../Components/FundManagement/Routes/FundMgt.route";
import { PermissionRoutes } from "../Components/Permission/Routes/Permission.route";
import { RoleRoutes } from "../Components/Role/Routes/Role.route";
import UOMRoutes from "../Components/UOM/Uom.route";
import UOMConversionRoutes from "./../Components/UOM-Conversion/UOMConversion.route";
import ReportRoutes from "../Components/Reports/Report.route";
import MetalInterchangeRoutes from "../Components/MetalInterchange/MetalInterchange.route";
import CustomerGroupRoutes from "../Components/CustomerGroup/CustomerGroup.route";
import DashboardRoutes from "../Components/Dashboard/Dashboard.route";
import PricingRoutes from "../Components/Pricing/Pricing.route";
import UtilRoutes from "../Components/Util/Util.route";
import AwsRoutes from "../Components/AWS/Aws.route";
import NFRRoutes from "../Components/NFR/NFR.route";
import { ManualRefundRoute } from "../Components/ManualRefund/ManualRefund.route";
import BankDetailsRoutes from "../Components/BankDetails/BankDetails.route";
import DashboardReportRoutes from "../Components/DashboardReports/DashboardReport.route";
import { DashboardCardRoutes } from "../Components/Statistics/Routes/DashboardCard.route";
// import { PushNotificationRoutes } from "../Components/PushNotification/PushNotification.route";

//new code
import BulkUploadRoutes from "../Components/BulkUpload/BulkUpload.route";
import { PushNotificationRoutes } from "../Components/PushNotification/PushNotification.route";
import InvestorsListRoutes from "../Components/Investors/Investorslist.route";
import CorporateGiftRoutes from "../Components/CorporateGift/Corporate.route";
import { SipRoutes } from "../Components/Sip/Routes/Sip.route";

export const routesArray = [
  {
    type: "UserListRoutes",
    permisson: ["user.view", "assign.warehouse", "assign.role", "user.create"],
    componenet: <UserListRoutes />,
  },
  {
    type: "InvestorsListRoutes",
    permisson: ["user.view", "assign.warehouse", "assign.role", "user.create"],
    componenet: <InvestorsListRoutes />,
  },
  {
    permisson: ["customer.list.edit", "customer.list.view"],
    componenet: <CustomerRoutes />,
    type: "CustomerRoutes",
  },

  {
    type: "PartnerRoutes",
    permisson: ["partner.list.create", "partner.list.edit"],
    componenet: <PartnerRoutes />,
  },
  {
    permisson: [""],
    componenet: <TestRoutes />,
  },
  {
    permisson: [
      "pricing.rule.master.create",
      "pricing.rule.master.view",
      "benchmark.view",
    ],
    componenet: <PricingRoutes />,
    type: "PricingRoutes",
  },
  {
    permisson: [],
    componenet: <DashboardRoutes />,
    type: "DashboardRoutes",
  },

  {
    permisson: [
      "user.view",
      "treasury.report.view",
      "treasury.report.edit",
      "trade.report.view",
      "trade.report.edit",
      "management.report.view",
      "management.report.edit",
    ],
    componenet: <DashboardReportRoutes />,
    type: "DashboardReportRoutes",
  },
  {
    permisson: [
      "pricing.rule.master.create",
      "pricing.rule.master.view",
      "benchmark.view",
    ],
    componenet: <PricingRoutes />,
    type: "PricingRoutes",
  },
  {
    permisson: ["document.type.edit", "document.type.create"],
    componenet: <UtilRoutes />,
    type: "UtilRoutes",
  },
  {
    permisson: ["daily.barlist.report.download", "vso.receipt"],
    componenet: <AwsRoutes />,
    type: "AwsRoutes",
  },
  {
    permisson: [],
    componenet: <NFRRoutes />,
    type: "NFRRoutes",
  },
  {
    permisson: [],
    componenet: <TradeExeRoutes />,
    type: "TradeExeRoutes",
  },
  {
    permisson: [
      "manual.refund.view",
      "manual.refund.approve",
      "manual.refund.cancel",
    ],
    componenet: <ManualRefundRoute />,
    type: "ManualRefundRoute",
  },
  {
    permisson: [
      "invoice.view",
      "invoice.download",
      "vso.receipt",
      "trading.rule.master.create",
      "price.rule.approve",
      "trading.rule.master.edit",
      "trading.rule.master.view",
    ],
    componenet: <TradeExecutionRoutes />,
    type: "TradeExecutionRoutes",
  },
  {
    permisson: [
      "discount.master.create",
      "discount.master.edit",
      "discount.master.view",
    ],
    componenet: <DiscountRoutes />,
    type: "DiscountRoutes",
  },
  {
    permisson: [
      "discount.master.create",
      // "discount.master.edit",
      // "discount.master.view",
    ],
    componenet: <SipRoutes />,
    type: "SipRoutes",
  },
  {
    permisson: ["dashboard.statistics.view"],
    componenet: <DashboardCardRoutes />,
    type: "DashboardCardRoutes",
  },
  {
    permisson: [
      "order.master.view",
      "order.master.view.invoice",
      "vso.receipt",
    ],
    componenet: <OrderRoutes />,
    type: "OrderRoutes",
  },
  {
    permisson: ["tax.category.master.create", "tax.category.master.edit"],
    componenet: <TaxCategoryRoutes />,
    type: "TaxCategoryRoutes",
  },
  {
    permisson: ["country.master.create", "country.master.edit"],
    componenet: <CountryRoutes />,
    type: "CountryRoutes",
  },
  {
    permisson: ["tax.master.create", "tax.master.edit"],
    componenet: <TaxRoutes />,
    type: "TaxRoutes",
  },
  {
    permisson: ["state.master.create", "state.master.edit"],
    componenet: <StateRoutes />,
    type: "StateRoutes",
  },
  {
    permisson: ["city.master.create", "city.master.edit"],
    componenet: <CityRoutes />,
    type: "CityRoutes",
  },
  {
    permisson: ["tax.details.master.create", "tax.details.master.edit"],
    componenet: <TaxDetailsRoutes />,
    type: "TaxDetailsRoutes",
  },
  {
    permisson: ["customs.duty.master.create", "customs.duty.master.edit"],
    componenet: <CustomsDutyRoute />,
    type: "CustomsDutyRoute",
  },
  {
    permisson: ["fund.management.report.download"],
    componenet: <FundMgtRoutes />,
    type: "FundMgtRoutes",
  },
  {
    permisson: ["customer.group.create", "customer.group.view"],
    componenet: <CustomerGroupRoutes />,
    type: "CustomerGroupRoutes",
  },
  {
    permisson: ["uom.master.create", "uom.master.edit"],
    componenet: <UOMRoutes />,
    type: "UOMRoutes",
  },
  {
    permisson: ["uom.conversion.master.create", "uom.conversion.master.edit"],
    componenet: <UOMConversionRoutes />,
    type: "UOMConversionRoutes",
  },
  {
    permisson: [
      "report.partner.settlement",
      "report.settlement.logs",
      "report.settlement",
      "report.bar.reconciliation",
      "vso.receipt",
    ],
    componenet: <ReportRoutes />,
    type: "ReportRoutes",
  },
  {
    permisson: ["permission.edit", "permission.remove", "permission.add"],
    componenet: <PermissionRoutes />,
    type: "PermissionRoutes",
  },
  {
    permisson: ["role.add", "role.edit", "role.remove"],
    componenet: <RoleRoutes />,
    type: "RoleRoutes",
  },
  {
    permisson: [
      "bank.details.edit",
      "bank.details.delete",
      "bank.details.create",
    ],
    componenet: <BankDetailsRoutes />,
    type: "BankDetailsRoutes",
  },
  {
    permisson: [
      "bar.to.bar.exchange",
      "quantity.to.bar.exchange",
      "bar.to.bar.approve",
      "quantity.to.bar.approve",
      "quantity.to.bar.exchange",
      "quantity.to.bar.approve",
      "bar.to.bar.exchange",
      "bar.to.bar.approve",
      "vso.receipt",
    ],
    componenet: <MetalInterchangeRoutes />,
    type: "MetalInterchangeRoutes",
  },
  // new code
  {
    permisson: ["user.upload.view"],
    // permisson: ["partner.list.create", "partner.list.edit"],
    componenet: <BulkUploadRoutes />,
    type: "BulkUploadRoutes",
  },
  {
    permisson: ["pushnotification.view"],
    componenet: <PushNotificationRoutes />,
    type: "PushNotificationRoutes",
  },
  {
    permisson: ["customer.list.view"],
    componenet: <CorporateGiftRoutes />,
    type: "CorporateGiftRoutes",
  },
];
