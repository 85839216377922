import Layout from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Components-ui/Loader";
import Service from "../CustomerGroup/CustomerGroup.Service";
import moment from "moment";
import { useAuthStore } from "../../Store/AuthStore";
import { Pagination } from "../../Components-ui/Pagination";
import Modal from "../../Components-ui/Modal/Modal";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";
import { useHistory } from "react-router-dom";
const CustomerGroupList: React.FC = () => {
  const { logout, permissions, userData, tokens } = useAuthStore();
  const history = useHistory();
  const [Loading, setLoading] = useState<any>(true);
  const [groupList, setGroupList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [meta, setMetaData] = useState<any>({});
  const [filters, setFilters] = useState<any>({
    id: "",
    name: "",
    active: "",
  });
  const [children, setChildren] = useState<any>();
  const [show, setModalStatus] = useState<any>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  useEffect(() => {
    fetchData({}, 0);
  }, []);

  const fetchData = async (data: any, page: any) => {
    setLoading(true);
    await Service.getGroupData(tokens, data, page)
      .then((response: any) => {
        setGroupList(response.data.data.result);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(response.data.meta.page);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.data.errors[0].detail);
      });
  };

  const ResetForm = async (e: any) => {
    e.preventDefault();
    setCurrentPage(0);
    setFilters({
      id: "",
      name: "",
      active: "",
    });
    await fetchData({}, 0);
  };

  const paginationFilter = async (page: number) => {
    setCurrentPage(page);
    fetchData(filters, page);
  };

  const editModalElements = async (item: any) => {
    setSelectedRecord(item);
    setModalStatus(true);
  };

  const childrenElements = () => {
    setChildren(
      <div className="w-full">
        <h1 className="font-bold">{"Customer Details"}</h1>
        <div>
          <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto mb-3">
            <thead>
              <tr className="text-left border-b border-gray-300">
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10 text-truncate">
                  Customer ID
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Name
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Customer Category
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Customer Type
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Partner Id
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Updated Date
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Status
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                  Source
                </th>
              </tr>
            </thead>
            <tbody>
              {0 !== selectedRecord.length ? (
                selectedRecord.map((data: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                    >
                      <td className="py-3 px-3 text-left relative">
                        {data.customerId}
                      </td>{" "}
                      <td className="py-3 px-3 text-left relative">
                        {data.displayName}
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        {data.customerCategory}
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        {data.customerType}
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        {data.partnerId}
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        {moment(data.updatedAt).format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        <span
                          className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${
                            "ENABLED" === data.active
                              ? "text-green-600 bg-green-200"
                              : "text-black-600 bg-gray-400"
                          }`}
                        >
                          {data.active}
                        </span>
                      </td>
                      <td className="py-3 px-3 text-left relative">
                        {data.source}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                  <td
                    colSpan={8}
                    className="text-center py-3 px-3 text-left relative"
                  >
                    No data to display
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  useEffect(() => {
    childrenElements();
  }, [selectedRecord, show]);

  const hideModal = () => {
    setModalStatus(false);
  };

  return (
    <Layout type={"UserModule"}>
      <ToastContainer />
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Customer Groups"}
        </h1>
        {HasAnyAccess(["customer.group.create"]) && (
          <button
            className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
            type="button"
            onClick={() => {
              history.push(`/group-creation`);
            }}
          >
            + Add Group
          </button>
        )}
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="flex-1 p-2">
          <form
            onSubmit={(e) => {
              fetchData(filters, 0);
              e.preventDefault();
            }}
          >
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-id"
                >
                  ID
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      id: event.target.value,
                    })
                  }
                  name="user-id"
                  id="user-id"
                  type="number"
                  placeholder="Id"
                  value={filters.id}
                  autoComplete="none"
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                />
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="email"
                >
                  Name
                </label>
                <input
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  id="email"
                  onChange={(event) =>
                    setFilters({
                      ...filters,
                      name: event.target.value,
                    })
                  }
                  type="text"
                  placeholder="Name"
                  value={filters.name}
                  autoComplete="none"
                />
              </div>
              <div className="pb-3 w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <label
                  className="block text-sm font-medium text-gray-700"
                  htmlFor="user-type"
                >
                  Status
                </label>
                <div className="relative">
                  <select
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="status"
                    onChange={(event) =>
                      setFilters({
                        ...filters,
                        active: event.target.value,
                      })
                    }
                    value={filters.active}
                  >
                    <option value="">Select a Status</option>
                    <option value="ENABLED">ENABLED</option>
                    <option value="DISABLED">DISABLED</option>
                  </select>
                </div>
              </div>
              <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                <div className="my-6">
                  {/* <button
                                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                                    type="button"
                                    onClick={() => fetchData(filters,0)}
                                >
                                    Filter
                                </button> */}
                  <input
                    className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
                    type="submit"
                    value="Filter"
                  />
                  <button
                    className="text-white rounded-md bg-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={(e) => ResetForm(e)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="border bg-white rounded border-gray-200 shadow">
        <div className="border bg-white rounded border-gray-200 shadow m-2">
          {Loading ? (
            <Loader />
          ) : (
            <div>
              <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
                <thead>
                  <tr className="text-left border-b border-gray-300">
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10 text-truncate">
                      Id
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10 text-truncate">
                      Name
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Number Of Customers
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Created By
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Updated Date
                    </th>
                    <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                      Status
                    </th>
                    {HasAnyAccess(["customer.group.view"]) && (
                      <th className="sticky top-0 px-3 py-2 bg-gray-800 z-10">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {0 !== groupList.length ? (
                    groupList.map((hsn: any, index: number) => {
                      return (
                        <tr
                          key={index}
                          className="border-b border-gray-200 bg-white text-black  hover:bg-gray-100"
                        >
                          <td className="py-3 px-3 text-left relative">
                            {hsn.id}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {hsn.name}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {hsn.customers.length}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {hsn.createdBy}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            {moment(hsn.updatedAt).format("DD-MM-YYYY HH:mm")}
                          </td>
                          <td className="py-3 px-3 text-left relative">
                            <span
                              className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1 ${
                                "ENABLED" === hsn.active
                                  ? "text-green-600 bg-green-200"
                                  : "text-black-600 bg-gray-400"
                              }`}
                            >
                              {hsn.active}
                            </span>
                          </td>
                          {HasAnyAccess(["customer.group.view"]) && (
                            <td className="py-3 px-3 text-left relative">
                              <button
                                className=" border border-purple-300 rounded-md bg-purple-100 background-transparent font-bold uppercase px-3 py-1 text-sm outline-none focus:outline-none mr-2  ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => {
                                  editModalElements(hsn.customers);
                                }}
                              >
                                View
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                      <td
                        colSpan={7}
                        className="text-center py-3 px-3 text-left relative"
                      >
                        No data to display
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            meta={meta}
            handlePageChange={(number: number) => {
              paginationFilter(number);
            }}
          />
        </div>
        <Modal
          size={"large"}
          show={show}
          handleClose={hideModal}
          children={children}
        />
      </div>
    </Layout>
  );
};   

export default CustomerGroupList;
