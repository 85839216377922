import Http from "../../../library/http.library";
import { httpMethods } from "../../../library/enum";
import { makeQuery } from "../../../Common/util.helper";
import axios from "axios";

export const getCustomerList = async (data: any, tokens: any, page: any) => {
  var query = {
    isPaginated: true,
    page: page,
    ...(data.customer_id ? { id: data.customer_id } : {}),
    ...(data.investorId ? { investorId: data.investorId } : {}),
    ...(data.user_id ? { userId: data.user_id } : {}),
    ...(data.mobile ? { mobile: data.mobile } : {}),
    ...(data.partner ? { partnerId: data.partner } : {}),
    ...(data.gst ? { customerType: data.gst } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data.verifyStatus ? { verifyStatus: data.verifyStatus } : {}),
    ...(data.customerCategory
      ? { customerCategory: data.customerCategory }
      : {}),
    ...(data.source ? { source: data.source } : {}),
    ...(data.name ? { name: data.name } : {}),
    ...(data.fromDate ? { fromDate: data.fromDate } : {}),
    ...(data.toDate ? { toDate: data.toDate } : {}),
    ...(data.flag ? { flag: data.flag } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/customers?${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getPartnerList = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/partners?isPaginated=false`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getCustomer = async (id: any, tokens: any, userId: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/customer-profile/${id}?user_id=${userId}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const getMetals = async () => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/metal-master`)
    .request();
};

export const getMetalLedger = async (id: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/metal-ledger?customerId=${id}`)
    .request();
};

export const getDocs = async (id: any) => {
  return Http.setMethod(httpMethods.get)
    .setUrl(`/customer/docs/${id}`)
    .request();
};

export const setVerification = async (id: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/customer/document/${id}`)
    .setData({
      isVerified: true,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateCustomer = async (id: any, data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/customer/${id}`)
    .setData({
      requiredStatus: data.requiredStatus,
    })
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateCustomerStatus = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/customer-required-status`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateAccount = async (id: any, data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/account/${id}`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const fileUpload = async (data: any, tokens: any) => {
  return new Promise(async (resolve, reject) => {
    let apiUrl = process.env.REACT_APP_URL + `/customer/document`;
    try {
      let result = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokens.idToken.jwtToken}`,
        },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export const getOtp = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.post)
    .setUrl(`/otp`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const verifyOtp = async (data: any, tokens: any) => {
  return Http.setMethod(httpMethods.patch)
    .setUrl(`/otp`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const updateBankDetails = async ( data: any, tokens: any) => {
  return Http.setMethod(httpMethods.post)
    .setUrl(`/transaction/customer/verify/bank-account`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const downloadSipReport = async(data: any, tokens: any) =>{
  var query = {
    isPaginated: true,
    ...(data.customer_id ? { id: data.customer_id } : {}),
    ...(data.user_id ? { userId: data.user_id } : {}),
    ...(data.mobile ? { mobile: data.mobile } : {}),
    ...(data.partner ? { partnerId: data.partner } : {}),
    ...(data.gst ? { customerType: data.gst } : {}),
    ...(data.status ? { status: data.status } : {}),
    ...(data.verifyStatus ? { verifyStatus: data.verifyStatus } : {}),
    ...(data.customerCategory
      ? { customerCategory: data.customerCategory }
      : {}),
    ...(data.source ? { source: data.source } : {}),
    ...(data.name ? { name: data.name } : {}),
    ...(data.fromDate ? { fromDate: data.fromDate } : {}),
    ...(data.toDate ? { toDate: data.toDate } : {}),
    ...(data.flag ? { flag: data.flag } : {}),
  };
  const params = makeQuery(query);
  return Http.setMethod(httpMethods.get)
    .unsetData()
    .setUrl(`/account/log/download?${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
}

const CustomerService = {
  getCustomerList,
  getPartnerList,
  getCustomer,
  getMetals,
  getMetalLedger,
  getDocs,
  setVerification,
  updateCustomer,
  updateAccount,
  fileUpload,
  updateCustomerStatus,
  getOtp,
  verifyOtp,
  updateBankDetails
};

export default CustomerService;
