import React from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import DashboardRoutes from "../Components/Dashboard/Dashboard.route";
import UserListRoutes from "../Components/Userlist/Userlist.route";
import CustomerRoutes from "../Components/Customer/Customer.route";
import PricingRoutes from "../Components/Pricing/Pricing.route";
import TestRoutes from "../Components/TestPage/Utils/Test.route";
import MetalRoutes from "../Components/Metal/Metal.route";
import LoginRoutes from "../Components/Index/Index.route";
import UtilRoutes from "../Components/Util/Util.route";
import AwsRoutes from "../Components/AWS/Aws.route";
import NFRRoutes from "../Components/NFR/NFR.route";
import { useAuthStore } from "../Store/AuthStore";
import PricingFeed from "../Components/TestPage/Compnents/PricingFeed";
import { TradeExeRoutes } from "../Components/TradeExe/Routes/TradeExe.route";
import { TradeExecutionRoutes } from "../Components/TradeExecution/Routes/TradeExecution.route";
import { OrderRoutes } from "../Components/Order/Routes/Order.route";
import { TaxCategoryRoutes } from "../Components/TaxCategory/Routes/TaxCategory.route";
import { DiscountRoutes } from "../Components/Discounts/Routes/Discount.route";
import { CountryRoutes } from "../Components/Country/Routes/Country.route";
import { TaxRoutes } from "../Components/Tax/Routes/Tax.route";
import { StateRoutes } from "../Components/State/Routes/State.route";
import { CityRoutes } from "../Components/City/Routes/City.route";
import { TaxDetailsRoutes } from "../Components/TaxDetails/Routes/TaxDetails.route";
import { CustomsDutyRoute } from "../Components/CustomsDuty/Routes/CustomsDuty.route";
import { FundMgtRoutes } from "../Components/FundManagement/Routes/FundMgt.route";
import { PermissionRoutes } from "../Components/Permission/Routes/Permission.route";
import { RoleRoutes } from "../Components/Role/Routes/Role.route";
import PartnerRoutes from "../Components/Partner/Partner.route";
import UOMRoutes from "../Components/UOM/Uom.route";
import UOMConversionRoutes from "./../Components/UOM-Conversion/UOMConversion.route";
import ReportRoutes from "../Components/Reports/Report.route";
import MetalInterchangeRoutes from "../Components/MetalInterchange/MetalInterchange.route";
import CustomerGroupRoutes from "../Components/CustomerGroup/CustomerGroup.route";
import { ManualRefundRoute } from "../Components/ManualRefund/ManualRefund.route";
import BankDetailsRoutes from "../Components/BankDetails/BankDetails.route";
import { KycSampleDocRoutes } from "../Components/KycSampleDoc/KycSampleDoc.route";
import ProtectedRoutes from "../Components-ui/ProtectedRoutes";
const Routes = () => {
  const { isAuthenticated } = useAuthStore();
  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Switch>
          <React.Fragment>
            <LoginRoutes />
            <Route path="/" exact />
            <Route path="*" />
            {/* <div>404 Not Found</div> */}
          </React.Fragment>
        </Switch>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <React.Fragment>
            <Route path="/" exact component={PricingFeed} />
            {/*<Route path="/unauthorized" exact component={UnAuthorized} />*/}
            {/* TODO: root path will add insted of PricingTable */}
            {/*<DashboardRoutes />*/}
            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"DashboardRoutes"}
            />
            {/*<UserListRoutes />*/}
            <ProtectedRoutes type={"UserListRoutes"} isAuth={isAuthenticated} />
            {/*<InvestorListRoutes />*/}
            <ProtectedRoutes  type={"InvestorsListRoutes"}  isAuth={isAuthenticated}/>
            {/*<CustomerRoutes />*/}
            <ProtectedRoutes isAuth={isAuthenticated} type={"CustomerRoutes"} />
            {/*/!*<CustomerRoutes />*!/*/}
            <ProtectedRoutes  type={"CorporateGiftRoutes"}  isAuth={isAuthenticated}/>

            {/*/!* <KycSampleDocRoutes/> *!/*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"PricingRoutes"} />
            {/*/!*<PricingRoutes />*!/*/}

            <MetalRoutes />
            <TestRoutes />

            <ProtectedRoutes isAuth={isAuthenticated} type={"UtilRoutes"} />
            {/*/!*<UtilRoutes />*!/*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"AwsRoutes"} />
            {/*/!*<AwsRoutes />*!/*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"NFRRoutes"} />
            {/*/!*<NFRRoutes />*!/*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"TradeExeRoutes"} />
            {/*/!*<TradeExeRoutes />*!/*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"SipRoutes"} />


            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"TradeExecutionRoutes"}
            />
            {/*/!*<TradeExecutionRoutes />*!/*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"ManualRefundRoute"}
            />
            {/*/!*<ManualRefundRoute />*!/*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"DiscountRoutes"} />
            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"DashboardCardRoutes"}
            />
            {/*<DiscountRoutes />*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"OrderRoutes"} />
            {/*<OrderRoutes />*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"TaxCategoryRoutes"}
            />
            {/*<TaxCategoryRoutes />*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"CountryRoutes"} />
            {/*<CountryRoutes />*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"TaxRoutes"} />
            {/*<TaxRoutes />*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"StateRoutes"} />
            {/*<StateRoutes />*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"CityRoutes"} />
            {/*<CityRoutes />*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"TaxDetailsRoutes"}
            />
            {/*<TaxDetailsRoutes />*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"CustomsDutyRoute"}
            />
            {/*<CustomsDutyRoute />*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"FundMgtRoutes"} />
            {/*<FundMgtRoutes/>*/}

            <ProtectedRoutes type={"PartnerRoutes"} isAuth={isAuthenticated} />

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"DashboardReportRoutes"}
            />
            {/*<DashboardReportRoutes/>*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"CustomerGroupRoutes"}
            />
            {/*<CustomerGroupRoutes/>*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"UOMRoutes"} />
            {/*<UOMRoutes/>*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"UOMConversionRoutes"}
            />
            {/*<UOMConversionRoutes/>*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"ReportRoutes"} />
            {/*<ReportRoutes/>*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"PermissionRoutes"}
            />
            {/*<PermissionRoutes/>*/}

            <ProtectedRoutes isAuth={isAuthenticated} type={"RoleRoutes"} />
            {/*<RoleRoutes/>*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"MetalInterchangeRoutes"}
            />
            {/*<MetalInterchangeRoutes/>*/}

            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"BankDetailsRoutes"}
            />

            {/* new code */}
            <ProtectedRoutes
              isAuth={isAuthenticated}
              type={"BulkUploadRoutes"}
            />
            {/* new end */}
            {/*<BankDetailsRoutes/>*/}
          </React.Fragment>
        </Switch>
      </BrowserRouter>
    );
  }
};

export default Routes;
