import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import GroupCreation from "./GroupCreation";
import CustomerGroupList from "./CustomerGroupList";

const CustomerGroupRoutes:React.FC = () => {
    return (
        <Fragment>
            <Route path="/group-creation" exact component={GroupCreation}/>
            <Route path="/group-list" exact component={CustomerGroupList}/>
        </Fragment>
    )
};

export default CustomerGroupRoutes;
