import { makeQuery } from "../../Common/util.helper";
import Http from "../../library/http.library";
import { httpMethods } from "../../library/enum";

export const getAllGroupData = async (tokens: any) => {
  return await Http.setMethod(httpMethods.get)
    .setUrl(
      `/customer-group?page=0&isPaginated=false&orderBy=created_at&order=desc`
    )
    .setToken(tokens.idToken.jwtToken)
    .request();
};
export const getGroupData = async (
  tokens: any,
  data: any,
  currentPage: any
) => {
  var query = {
    isPaginated: true,
    ...(data.id ? { id: data.id } : {}),
    ...(data.name ? { name: data.name } : {}),
    ...(data.active ? { active: data.active } : {}),
  };
  const params = makeQuery(query);
  return await Http.setMethod(httpMethods.get)
    .setUrl(`/customer-group?page=${currentPage}${params}`)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const saveCustomerGroup = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/customer-group`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

export const assignCustomers = async (tokens: any, data: any) => {
  return await Http.setMethod(httpMethods.post)
    .setUrl(`/customer-group-customer`)
    .setData(data)
    .setToken(tokens.idToken.jwtToken)
    .request();
};

const CustomerGroupService = {
  getGroupData,
  saveCustomerGroup,
  assignCustomers,
  getAllGroupData,
};

export default CustomerGroupService;
