import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import PricingService from "./Services/pricing.service";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useStore } from "../../Store/PricingStore";
import { useAuthStore } from "../../Store/AuthStore";
import Datetime from "react-datetime";
import { makeQuery } from "../../Common/util.helper";
import { Pagination } from "../../Components-ui/Pagination";
import { toast } from "react-toastify";
import Loader from "../../Components-ui/Loader";
import HasAnyAccess from "../../Components-ui/hasAnyAccess";

const PricingTable: React.FC = () => {
  const [meta, setMetaData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pricingMater, setPricingMaster] = useState<any>();
  const [partnerMaster, setPartnerMaster] = useState<any>([]);
  const [metal, setMetal] = useState<any>([]);
  const [bench, setBench] = useState<any>([]);
  const [feed, setFeed] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(true);
  const [filters, setFilters] = useState<any>({
    metalCode: "",
    name: "",
    defaultRule: "",
    effectDate: "",
    partnerId: "",
  });

  const setDisable = useStore((state) => state.defOpen);
  let history = useHistory();
  const { logout, permissions, userData, tokens } = useAuthStore();

  useEffect(() => {
    loadData(currentPage);
    fetchParterMaster();
    fetchMetalList();
    fetchBenchList();
    loadFeedData();
    // commented due to nsv fix
    // localStorage.removeItem("nsvDefaultAsk");
    // localStorage.removeItem("nsvDefaultBid");
    // localStorage.removeItem("spreadDefaultBid");
  }, []);

  useEffect(() => {
    getPartnerNameFromId(1);
  }, [partnerMaster]);

  const fetchParterMaster = async () => {
    let params = {
      isPaginated: false,
    };
    const query = makeQuery(params);

    await PricingService.getPartnerMasterData(tokens, query)
      .then((res: any) => {
        setPartnerMaster(res.data.data);
      })
      .catch((err) => {
        toast.error("Benchmark data load error!");
      });
  };

  const fetchMetalList = async () => {
    let params = {
      isPaginated: false,
    };
    const query = makeQuery(params);

    await PricingService.getMetalMasterData(tokens, query)
      .then((res: any) => {
        setMetal(res.data.data);
      })
      .catch((err) => {
        toast.error("Metal data load error!");
      });
  };

  const fetchBenchList = async () => {
    let params = {
      isPaginated: false,
    };
    const query = makeQuery(params);

    await PricingService.getBenchData(tokens, 0, false)
      .then((res: any) => {
        // console.log("res.data 1111", res.data);

        setBench(res.data.data);
      })
      .catch((err) => {
        toast.error("Bench data load error!");
      });
  };

  const loadFeedData = async () => {
    let res: any = await PricingService.getFeedMaster();
    setFeed(res.data.data);
  };

  const loadData = async (page: number) => {
    setLoading(true);

    await PricingService.getPricingMaster(tokens, filters, page)
      .then((response: any) => {
        setPricingMaster(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Price rule data load error!");
      });

    // let data: any;

    // let newQuery: any = {};
    // for (const key in FilterData) {
    //   if (FilterData[key]) {
    //     newQuery[key] = FilterData[key];
    //   }
    // }

    // if (Object.keys(newQuery).length === 0 || reset) {
    //   data = await PricingService.getPricingMaster(null);
    // } else {
    //   const query = makeQuery(newQuery);
    //   data = await PricingService.getPricingMaster(query);
    // }

    // if (data && data?.data?.data) {
    //   let pricingData = data.data.data;
    //   pricingData.map((tempData: any) => {
    //     tempData.status = setLogStatusState(tempData.status);
    //   });
    //   setPricingMaster(pricingData);

    // }
  };

  // const setLogStatusState = (status: string) => {
  //   let tempStatus = status;
  //   // if (status == "L") {
  //   //   tempStatus = "Logged";
  //   // }
  //   // if (status == "S") {
  //   //   tempStatus = "Send for approval";
  //   // }
  //   // if (status == "E") {
  //   //   tempStatus = "Expire";
  //   // }
  //   // if (status == "A") {
  //   //   tempStatus = "Approved";
  //   // }
  //   return tempStatus;
  // };

  const addNewRule = async () => {
    try {
      setDisable();
      let length = !!pricingMater?.length ? pricingMater.length : 0;
      const newBenchMark = bench?.filter(
        (data: any) => data.metalCode === metal[0].metalCode
      )[0];

      const startDateTime = new Date();
      var endDateTime = new Date();
      endDateTime.setTime(endDateTime.getTime() + 1 * 60 * 60 * 1000);
      let priceMaster = {
        ruleName: "P" + length,
        startDate: startDateTime.toISOString(),
        endDate: endDateTime.toISOString(),
        metalCode: metal[0].metalCode,
        partnerId: partnerMaster[0].partnerId,
        benchmarkId: newBenchMark.id,
        feedCode: newBenchMark.feed,
      };
      // console.log("priceMaster ",priceMaster,newBenchMark);
      let data: any = await PricingService.createPricingMaster(
        priceMaster,
        tokens
      );

      if (!!data?.data?.data?.id) {
        let masterId = data.data.data.id;

        history.push({
          pathname: `/pricing/${masterId}`,
          state: { operation: "create" },
        });
        window.location.reload();
      }
    } catch (ex) {
      console.warn(ex);
    }
  };

  const getPartnerNameFromId = (id: any) => {
    const partnerObj = partnerMaster.find((data: any) => data.partnerId == id);
    if (partnerObj) return partnerObj.name;
    else return "";
  };

  const onClickEdit = (id: number) => {
    history.push({
      pathname: `/pricing/${id}`,
      state: { operation: "edit" },
    });
    window.location.reload();
  };

  const handleChangeFilter = (e: any) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const ResetForm = async (e: any) => {
    setCurrentPage(0);
    e.preventDefault();
    setFilters({
      metalCode: "",
      name: "",
      defaultRule: "",
      effectDate: "",
      partnerId: "",
      feedCode: "",
      benchmark: "",
      status: "",
    });

    setLoading(true);
    await PricingService.getPricingMaster(tokens, {})
      .then((response: any) => {
        setPricingMaster(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(0);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const filter = async (page: any) => {
    setLoading(true);
    await PricingService.getPricingMaster(tokens, filters, page)
      .then((response: any) => {
        setPricingMaster(response.data.data);
        setMetaData({
          current: response.data.meta.page,
          pages: response.data.meta.totalPages,
        });
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const paginationFilter = async (page: number) => {
    console.log(page);

    setCurrentPage(page);
    filter(page);
  };

  const changeStatus = async (e: any, pid: any, priceStatus: any) => {
    try {
      const data = {
        id: pid,
        status: priceStatus == "ENABLED" ? "DISABLED" : "ENABLED",
      };
      const res = await PricingService.changePriceStatus(data, tokens);
      if (res.status == 200) {
        toast.success("Status changed successfully");
        loadData(currentPage);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout type={"PricingModule"}>
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold tracking-tight">
          {"Pricing Rule Master"}
        </h1>
        {HasAnyAccess(["pricing.rule.master.create"]) &&
          partnerMaster.length > 0 && (
            <button
              className="bg-purple-950 text-white px-4 py-2 rounded font-bold text-sm"
              onClick={addNewRule}
              type="button"
            >
              + Add Pricing Rules
            </button>
          )}
      </div>
      {/* filter rules */}

      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Filter Rules
        </h3>
      </div>

      <hr />
      <form
        onSubmit={(e) => {
          loadData(0);
          e.preventDefault();
        }}
      >
        <div className="px-4 pt-2 pb-4 sm:px-6 grid grid-cols-12 gap-5">
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="metalCode"
              className="block text-sm font-medium text-gray-700"
            >
              Metal
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="metalCode"
              name="metalCode"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.metalCode}
            >
              <option value="">All</option>

              {metal.map((key: any, index: number) => {
                return (
                  <option key={index} value={key.metalCode}>
                    {key.metalCode}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Rule Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.name}
            />
          </div>
          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="partnerId"
              className="block text-sm font-medium text-gray-700"
            >
              Partner
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="partnerId"
              name="partnerId"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.partnerId}
            >
              <option value="">All</option>

              {partnerMaster.map((key: any, index: number) => {
                return (
                  <option key={index} value={key.partnerId}>
                    {key.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="defaultRule"
              className="block text-sm font-medium text-gray-700"
            >
              Default Rule
            </label>
            <select
              id="defaultRule"
              name="defaultRule"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.defaultRule}
            >
              <option value="">All</option>
              <option value="true">YES</option>
              <option value="false">NO</option>
            </select>
          </div>

          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="effectDate"
              className="block text-sm font-medium text-gray-700"
            >
              Effective Date
            </label>
            <div style={{ backgroundColor: "white" }}>
              <Datetime
                timeFormat={""}
                dateFormat={"DD/MM/YYYY"}
                inputProps={{
                  placeholder: "pick date",
                  className: "focus:outline-none",
                }}
                className={`rounded-md shadow-sm appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-2 px-4
             `}
                onChange={(event: any) =>
                  setFilters({
                    ...filters,
                    effectDate: moment(new Date(event).toISOString()).format(
                      "MM/DD/YYYY"
                    ),
                  })
                }
                value={moment(filters.effectDate)}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      value={filters.effectDate ? filters.effectDate : ""}
                    />
                  );
                }}
              />
            </div>
          </div>


          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="metalCode"
              className="block text-sm font-medium text-gray-700"
            >
              Feed
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="feedCode"
              name="feedCode"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.feedCode}
            >
              <option value="">All</option>

              {feed.map((key: any, index: number) => {
                return (
                  <option key={index} value={key.feedCode}>
                    {key.feedCode}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="metalCode"
              className="block text-sm font-medium text-gray-700"
            >
              Benchmark
            </label>
            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="benchmark"
              name="benchmark"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.benchmark}
            >
              <option value="">All</option>

              {bench.map((key: any, index: number) => {
                return (
                  <option key={index} value={key.basisCode}>
                    {key.basisCode}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-span-12 sm:col-span-3">
            <label
              htmlFor="metalCode"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>

            <select
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="status"
              name="status"
              onChange={(e: any) => {
                handleChangeFilter(e);
              }}
              value={filters.status}
            >
              <option value="">All</option>
              <option value="LOGGED">LOGGED</option>
              <option value="EXPIRED">EXPIRED</option>
              <option value="APPROVED">APPROVED</option>
            </select>
          </div>

          <div className="col-span-12 sm:col-span-12 flex justify-end">
            {/* <button
            className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
            type="button"
            onClick={() => loadData(0)}
          >
            Filter
          </button> */}
            <input
              className="text-white bg-purple-950 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  ease-linear transition-all duration-150 rounded-md mr-2"
              type="submit"
              value="Filter"
            />
            <button
              className="text-white bg-red-600 border border-red-300 rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={(e) => ResetForm(e)}
            >
              Reset
            </button>
          </div>
          <div className="col-span-12 sm:col-span-12">
            {" "}
            <hr />
          </div>
        </div>
      </form>
      {/* end filter */}
      <div className="border bg-white rounded border-gray-200 shadow">
        {Loading ? (
          <Loader />
        ) : (
          <table className="rounded w-full mx-auto bg-gray-800 text-gray-200 text-xs table-auto">
            <thead>
              <tr className="text-left border-b border-gray-300">
                <th className="sticky top-0 px-3 py-2 bg-gray-800">Rule Ref</th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">Feed</th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">Metal</th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">
                  Rule Name
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">Type</th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">
                  Benchmark
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">Partner</th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">
                  Effective From
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">
                  Effective To
                </th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                <th className="sticky top-0 px-3 py-2 bg-gray-800">
                  Default Rule
                </th>
                {HasAnyAccess(["pricing.rule.master.view"]) && (
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">
                    Actions
                  </th>
                )}
                {HasAnyAccess(["pricing.rule.master.view"]) && (
                  <th className="sticky top-0 px-3 py-2 bg-gray-800">Status</th>
                )}
              </tr>
            </thead>
            <tbody>
              {0 !== pricingMater.length ? (
                pricingMater.map((ebl: any) => {
                  return (
                    <tr className="bg-white border-b border-gray-300 text-black">
                      <td className="px-3 py-3">{ebl.id}</td>
                      <td className="px-3 py-3">
                        {ebl.feedCode ? ebl.feedCode : <EmptyValue />}
                      </td>
                      <td className="px-3 py-3">
                        {ebl.metalCode ? ebl.metalCode : <EmptyValue />}
                      </td>
                      <td className="px-3 py-3">{ebl.ruleName}</td>
                      <th className="px-3 py-3">
                        {ebl.type ? ebl.type : <EmptyValue />}
                      </th>
                      <th className="px-3 py-3">
                        {ebl.benchmarkBasisCode ? (
                          ebl.benchmarkBasisCode
                        ) : (
                          <EmptyValue />
                        )}
                      </th>
                      <th className="px-3 py-3">
                        {ebl?.partnerId ? (
                          getPartnerNameFromId(ebl?.partnerId)
                        ) : (
                          <EmptyValue />
                        )}
                      </th>
                      <td className="px-3 py-3">
                        {moment(ebl.startDate).format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td className="px-3 py-3">
                        {moment(ebl.endDate).format("DD-MM-YYYY HH:mm")}
                      </td>
                      <td className="px-3 py-3">
                        <span className="whitespace-nowrap bg-yellow-100 p-2 px-3 text-xs uppercase font-bold rounded">
                          {ebl.status ? ebl.status : <EmptyValue />}
                        </span>
                      </td>
                      <td className="px-3 py-3">
                        <span className="whitespace-nowrap p-2 px-3 text-xs uppercase font-bold rounded">
                          {ebl.defaultRule ? (
                            <svg
                              className="default-rule"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z" />
                            </svg>
                          ) : (
                            <EmptyValue />
                          )}
                        </span>
                      </td>
                      <td className="px-3 py-3 text-right ">
                        {HasAnyAccess(["pricing.rule.master.view"]) && (
                          <button
                            className="whitespace-nowrap bg-purple-100 text-purple-900 px-3 py-1 text-sm rounded font-bold cursor-pointer"
                            onClick={() => onClickEdit(ebl.id)}
                          >
                            View
                          </button>
                        )}
                      </td>
                        {HasAnyAccess(["pricing.rule.master.view"]) && (
                      <td className="px-3 py-3  ">
                          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input
                              type="checkbox"
                              name="toggle"
                              id={`${ebl.id}toggle`}
                              checked={ebl.isEnabled === "ENABLED"}
                              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                              onChange={(e) =>
                                changeStatus(e, ebl.id, ebl.isEnabled)
                              }
                            />
                            <label
                              htmlFor={`${ebl.id}toggle`}
                              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            />
                          </div>
                      </td>
                        )}
                    </tr>
                  );
                })
              ) : (
                <tr className="border-b border-gray-200 bg-white text-black hover:bg-gray-100">
                  <td
                    colSpan={12}
                    className="text-center py-3 px-3 text-left relative"
                  >
                    No data to display
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <Pagination
          meta={meta}
          handlePageChange={(number: number) => {
            paginationFilter(number);
          }}
        />
      </div>
    </Layout>
  );
};

const EmptyValue = () => (
  <span
    className="bg-gray-50 px-2
py-1 font-bold text-gray-500 text-xs border-gray-300 border rounded"
  >
    Empty
  </span>
);

export default PricingTable;
